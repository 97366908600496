import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { NumericFormat } from 'react-number-format';
import { extractColors } from 'extract-colors'
import Convert from 'color-convert';
import Slider from 'rc-slider';
import { RiMenu5Fill, RiLayoutGridFill, RiImageCircleFill, RiExternalLinkLine,RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';

import { ReactComponent as Logo } from './../assets/img/logo.svg';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      image: '',
      result: [],
      hexOpen: '',
      pixels: 64000,
      distance: 22,
      saturationDistance: 20,
      lightnessDistance: 20,
      hueDistance: 8,
      area: false
    }

    this.copy = 2024;
    this.today = new Date();

    this.inputChange = this.inputChange.bind(this);
    this.changeSlider = this.changeSlider.bind(this);
    this.fileDrop = this.fileDrop.bind(this);
    this.fileDragOver = this.fileDragOver.bind(this);
    this.fileClick = this.fileClick.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.changeView = this.changeView.bind(this);
    this.openHex = this.openHex.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }
  
  componentDidMount(){
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState){
    if(
      this.state.image !== prevState.image ||
      this.state.pixels !== prevState.pixels ||
      this.state.distance !== prevState.distance ||
      this.state.saturationDistance !== prevState.saturationDistance ||
      this.state.lightnessDistance !== prevState.lightnessDistance ||
      this.state.hueDistance !== prevState.hueDistance
    ){
      if(
        this.state.image &&
        this.state.pixels &&
        this.state.distance &&
        this.state.saturationDistance &&
        this.state.lightnessDistance &&
        this.state.hueDistance
      ){
        extractColors(this.state.image, {
          pixels: this.state.pixels ? this.state.pixels : 64000,
          distance: this.state.distance / 100,
          saturationDistance: this.state.saturationDistance / 100,
          lightnessDistance: this.state.lightnessDistance / 100,
          hueDistance: this.state.hueDistance / 100
        }).then((response) => {
          let listColor = [];
          
          response.forEach((value, index) => {
            console.log(value);

            const area = parseFloat((value.area * 100).toFixed(1));

            if(area){
              const rgb = `rgb(${value.red}, ${value.green}, ${value.blue})`,
                    hsl = Convert.hex.hsl(value.hex),
                    rgbRed = parseFloat(((value.red / 255) * 100).toFixed(0)),
                    rgbGreen = parseFloat(((value.green / 255) * 100).toFixed(0)),
                    rgbBlue = parseFloat(((value.blue / 255) * 100).toFixed(0)),
                    cmyk = Convert.hex.cmyk(value.hex),
                    cmykCyan = parseFloat(((cmyk[0] / 255) * 100).toFixed(0)),
                    cmykMagenta = parseFloat(((cmyk[1] / 255) * 100).toFixed(0)),
                    cmykYellow = parseFloat(((cmyk[2] / 255) * 100).toFixed(0)),
                    cmykBlack = parseFloat(((cmyk[3] / 255) * 100).toFixed(0));

              listColor.push({
                area: value.area * 100,
                hex: value.hex,
                // rgb: rgb,

                ansi16: Convert.hex.ansi16(value.hex),
                ansi256: Convert.hex.ansi256(value.hex),
                apple: Convert.hex.apple(value.hex),
                cmyk: cmyk,
                cyan: cmykCyan,
                magenta: cmykMagenta,
                yellow: cmykYellow,
                black: cmykBlack,
                totalCmyk: cmykCyan + cmykMagenta + cmykYellow + cmykBlack,
                gray: Convert.hex.gray(value.hex),
                hcg: Convert.hex.hcg(value.hex),
                hsl: hsl,
                hsv: Convert.hex.hsv(value.hex),
                hwb: Convert.hex.hwb(value.hex),
                keyword: Convert.hex.keyword(value.hex),
                lab: Convert.hex.lab(value.hex),
                lch: Convert.hex.lch(value.hex),
                rgb: rgb,
                xyz: Convert.hex.xyz(value.hex),
                red: rgbRed,
                green: rgbGreen,
                blue: rgbBlue,
                totalRgb: rgbRed + rgbGreen + rgbBlue,
                hue: ((hsl[0] / 360) * 100).toFixed(0),
                bright: 100 - cmyk[3]
              });
            }
          });

          listColor.sort((a, b) => b.area - a.area);
  
          this.setState({ result: listColor });
        }).catch(console.error);
      }
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  changeSlider(value, name){
    this.setState({ [name]: value })
  }

  inputChange(value, event){
    if(typeof event.event !== 'undefined'){
      this.setState({ [event.event.target.name]: value.floatValue });
    }
  }

  fileDrop(event){
    event.preventDefault();
    
    this.setState({ image: window.URL.createObjectURL(event.dataTransfer.items[0].getAsFile()) });
  }

  fileDragOver(event){
    event.preventDefault();
  }

  fileClick(){
    document.getElementById('image').click();
  }

  changeFile(event){
    this.setState({ image: window.URL.createObjectURL(event.target.files[0]) });
  }

  changeView(){
    this.setState({ area: !this.state.area });
  }

  openHex(event){
    if(event.target.dataset.hex === this.state.hexOpen){
      this.setState({ hexOpen: '' });
    }else{
      this.setState({ hexOpen: event.target.dataset.hex });
    }
  }

  handleResize(){
    let width = 16.66,
        landscape = true,
        tablet = false,
        mobile = false;

    if(window.innerWidth < window.innerHeight){
      landscape = false;
    }

    if(window.innerWidth <= 767){
      tablet = false;
      mobile = true;
    }else if(window.innerWidth >= 768 && window.innerWidth <= 1024){
      tablet = true;
      mobile = false;
    }

    if(tablet){
      if(landscape){
        // width = 25;
      }else{
        width = 25;
      }
    }else if(mobile){
      if(landscape){
        width = 25;
      }else{
        width = 50;
      }
    }

    this.setState({ maxWidth: width });
  }

  render(){
    const percent = this.state.result.length ? 100 / this.state.result.length : '';

    return (
      <>
        <Helmet>
          <title>ColorExtract {this.state.result.length ? `${this.state.result.length} Color${this.state.result.length > 1 ? 's' : ''} Extracted` : ''}</title>
        </Helmet>

        <header>
          <div>
            <a href="/">
              <Logo />
            </a>
            <ul>
              <li>
                <label>Distance</label>
                <div className="slider">
                  <Slider min={0} max={100} value={this.state.distance} onChange={(value) => {this.changeSlider(value, 'distance')}} />
                  <span>{this.state.distance}</span>
                </div>
                {/* <input type="text" name="distance" placeholder="Distance" value={this.state.distance} onChange={this.inputChange} className="no-unit" /> */}
              </li>
              <li>
                <label>Hue Distance</label>
                <div className="slider">
                  <Slider min={0} max={100} value={this.state.hueDistance} onChange={(value) => {this.changeSlider(value, 'hueDistance')}} />
                  <span>{this.state.hueDistance}</span>
                </div>
                {/* <input type="text" name="hueDistance" placeholder="Hue Distance" value={this.state.hueDistance} onChange={this.inputChange} className="no-unit" /> */}
              </li>
              <li>
                <label>Saturation Distance</label>
                <div className="slider">
                  <Slider min={0} max={100} value={this.state.saturationDistance} onChange={(value) => {this.changeSlider(value, 'saturationDistance')}} />
                  <span>{this.state.saturationDistance}</span>
                </div>
                {/* <input type="text" name="saturationDistance" placeholder="Saturation Distance" value={this.state.saturationDistance} onChange={this.inputChange} className="no-unit" /> */}
              </li>
              <li>
                <label>Lightness Distance</label>
                <div className="slider">
                  <Slider min={0} max={100} value={this.state.lightnessDistance} onChange={(value) => {this.changeSlider(value, 'lightnessDistance')}} />
                  <span>{this.state.lightnessDistance}</span>
                </div>
                {/* <input type="text" name="lightnessDistance" placeholder="Lightness Distance" value={this.state.lightnessDistance} onChange={this.inputChange} className="no-unit" /> */}
              </li>
              <li>
                <label>Pixel</label>
                {/* <input type="text" name="pixels" placeholder="Pixels" value={this.state.pixels} onChange={this.inputChange} className="no-unit" /> */}
                <NumericFormat
                  name="pixels"
                  placeholder="Pixels"
                  thousandSeparator="."
                  decimalSeparator=","
                  value={this.state.pixels}
                  allowNegative={false}
                  onValueChange={this.inputChange}
                  // className="no-unit"
                  pattern="[0-9]*"
                />
                <span className="unit">px</span>
              </li>
              <li>
                <button type="button" onClick={this.changeView}>
                  <span>Result View</span>
                  {this.state.area ? (
                    <>
                      <RiMenu5Fill />Area
                    </>
                  ) : (
                    <>
                      <RiLayoutGridFill />Thumbnail
                    </>
                  )}
                </button>
              </li>
            </ul>
          </div>
        </header>
        
        <div className={`container ${this.state.area ? 'area' : ''}`}>
          {this.state.result.length ? (
            <ul>
              {this.state.result.map((value, index) => (
                <li key={value.hex} className={this.state.hexOpen === value.hex ? 'big' : ''} style={{width: this.state.area ? `${value.area}%` : `${percent < this.state.maxWidth ? this.state.maxWidth : percent}%`}}>
                  <button style={{ backgroundColor: value.hex }} onClick={this.openHex} data-hex={value.hex}>
                    {!this.state.area || this.state.hexOpen === value.hex ? (
                      <ul>
                        <li>{parseFloat((value.area).toFixed(2))}%</li>
                        <li>{value.hex}</li>
                        <li>{value.rgb}</li>
                        {this.state.hexOpen === value.hex ? (
                          <li>
                            <ul className="color-info">
                              <li>
                                <span>ANSI-16</span>
                                {value.ansi16}
                              </li>
                              <li>
                                <span>ANSI-256</span>
                                {value.ansi256}
                              </li>
                              <li>
                                <span>Apple</span>
                                apple({value.apple[0]}, {value.apple[1]}, {value.apple[2]})
                              </li>
                              <li>
                                <span>Closest CSS Keyword</span>
                                <div className="colorname" style={{ background: value.keyword }}></div>{value.keyword}
                              </li>
                              <li>
                                <span>CMYK</span>
                                cmyk({value.cmyk[0]}, {value.cmyk[1]}, {value.cmyk[2]}, {value.cmyk[3]})
                              </li>
                              <li>
                                <span>Gray</span>
                                {value.gray[0]}
                              </li>
                              <li>
                                <span>HCG</span>
                                hcg({value.hcg[0]}, {value.hcg[1]}, {value.hcg[2]})
                              </li>
                              <li>
                                <span>HSL</span>
                                hsl({value.hsl[0]}, {value.hsl[1]}, {value.hsl[2]})
                              </li>
                              <li>
                                <span>HSV</span>
                                hsv({value.hsv[0]}, {value.hsv[1]}, {value.hsv[2]})
                              </li>
                              <li>
                                <span>HWB</span>
                                hwb({value.hwb[0]}, {value.hwb[1]}, {value.hwb[2]})
                              </li>
                              <li>
                                <span>LAB</span>
                                lab({value.lab[0]}, {value.lab[1]}, {value.lab[2]})
                              </li>
                              <li>
                                <span>LCH</span>
                                lch({value.lch[0]}, {value.lch[1]}, {value.lch[2]})
                              </li>
                              <li>
                                <span>XYZ</span>
                                xyz({value.xyz[0]}, {value.xyz[1]}, {value.xyz[2]})
                              </li>
                              <li>
                                <span>Color Name</span>
                                <a href={`https://colorname.nabilamerthabit.com/${value.hex}`} target="_blank" rel="noreferrer">View on ColorName <RiExternalLinkLine /></a>
                              </li>
                              <li className="lay2 clear same">
                                <span>Hue <strong>{value.hue}%</strong></span>
                                <div className="chart">
                                  <span className="huepicker">
                                    <div className="pointer" style={{ left: value.hue + '%' }}></div>
                                  </span>
                                </div>
                              </li>
                              <li className="lay2 same">
                                <span>Brightness <strong>{value.bright}%</strong></span>
                                <div className="chart">
                                  <span className="darkpicker">
                                    <div className="pointer" style={{ left: value.bright + '%' }}></div>
                                  </span>
                                </div>
                              </li>
                              <li className="lay2">
                                <ul>
                                  <li className="lay3">
                                    <span>RGB - Red <strong>{value.red}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'red', width: value.red + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="lay3">
                                    <span>RGB - Green <strong>{value.green}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'green', width: value.green + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="lay3">
                                    <span>RGB - Blue <strong>{value.blue}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'blue', width: value.blue + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="layfull">
                                    <span>RGB - Scheme</span>
                                    <div className="chart multi">
                                      <span style={{ background: 'red', width: ((value.red / value.totalRgb) * 100) + '%' }}></span>
                                      <span style={{ background: 'green', width: ((value.green / value.totalRgb) * 100) + '%' }}></span>
                                      <span style={{ background: 'blue', width: ((value.blue / value.totalRgb) * 100) + '%' }}></span>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li className="lay2">
                                <ul>
                                  <li className="lay4">
                                    <span>CMYK - Cyan <strong>{value.cmyk[0]}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'cyan', width: value.cyan + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="lay4">
                                    <span>CMYK - Magenta <strong>{value.cmyk[1]}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'magenta', width: value.magenta + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="lay4">
                                    <span>CMYK - Yellow <strong>{value.cmyk[2]}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'yellow', width: value.yellow + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="lay4">
                                    <span>CMYK - Black <strong>{value.cmyk[3]}%</strong></span>
                                    <div className="chart">
                                      <span style={{ background: 'black', width: value.black + '%' }}></span>
                                    </div>
                                  </li>
                                  <li className="layfull">
                                    <span>CMYK - Scheme</span>
                                    <div className="chart multi">
                                      <span style={{ background: 'cyan', width: ((value.cyan / value.totalCmyk) * 100) + '%' }}></span>
                                      <span style={{ background: 'magenta', width: ((value.magenta / value.totalCmyk) * 100) + '%' }}></span>
                                      <span style={{ background: 'yellow', width: ((value.yellow / value.totalCmyk) * 100) + '%' }}></span>
                                      <span style={{ background: 'black', width: ((value.black / value.totalCmyk) * 100) + '%' }}></span>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        ) : ''}
                      </ul>
                    ) : ''}
                  </button>
                </li>
              ))}
            </ul>
          ) : ''}

          <div>
            <button type="button" onDrop={this.fileDrop} onDragOver={this.fileDragOver} onClick={this.fileClick} className={this.state.image ? 'img' : ''}>
              <div>
                <RiImageCircleFill />
                Drag and drop the image file or click to select the file to upload
              </div>
              {this.state.image ? (
                <img src={this.state.image} alt="Extract Preview" />
              ) : ''}
            </button>
            <input type="file" accept="image/png, image/jpeg" id="image" style={{display: 'none'}} onChange={this.changeFile} /> 
          </div>
        </div>

        <footer>
          <a href="/">ColorExtract</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
        </footer>
      </>
    );
  }
}

export default App;